@import "~@ng-select/ng-select/themes/default.theme.css";
@import "ngx-toastr/toastr";

.list-group-item {
  background-color: transparent !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-none {
  cursor: not-allowed;
}

html,
body {
  height: auto;
  margin: 0;
}

a.badge {
  text-decoration: none;
}

tabset.edit ul .active.nav-item .nav-link.active {
  background-color: #ECF2F7 !important;
  border-bottom-color: transparent;
  box-shadow: none;
}

tabset ul .active.nav-item .nav-link.active {
  background-color: #ffffff !important;
  border-bottom-color: transparent;
  box-shadow: none;
}

.toast-container {
  --cui-toast-zindex: 99999 !important;
  z-index: 99999;
}